.wrapperSection {
	border-bottom: 1px solid #ced4da;
	margin: 20px;
	padding: 20px;
	h1 {
		padding-bottom: 10px;
	}
	h2 {
		font-weight: normal;
		font-size: 20px;
	}

	button {
		display: inline-block;
		font-weight: 400;
		text-align: center;
		white-space: nowrap;
		border: 1px solid transparent;
		padding: 0.375rem 0.75rem;
		font-size: 1rem;
		line-height: 1.5;
		border-radius: 0.25rem;
		transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out,
			border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
		&.-light {
			color: #212529;
			background-color: #f8f9fa;
			border-color: #f8f9fa;
		}
		&:hover {
			color: #212529;
			background-color: #e2e6ea;
			border-color: #dae0e5;
		}
	}

	.inputHolder {
		display: flex;
		flex-direction: column;
		flex-wrap: wrap;
	}

	.inputDiv {
		display: flex;
		flex-direction: column;
		padding: 10px 10px 10px 0;
		width: 100%;
		label {
			padding-top: 10px;
			padding-bottom: 20px;
			max-width: 500px;
		}
		input {
			display: block;
			max-width: 500px;
			padding: 0.375rem 0.75rem;
			font-size: 1rem;
			font-weight: 400;
			line-height: 1.5;
			color: #212529;
			background-color: #fff;
			background-clip: padding-box;
			border: 1px solid #ced4da;
			appearance: none;
			border-radius: 0.25rem;
			transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
		}
	}
}

@media screen and (max-width: 992px) {
	.wrapperSection {
		.inputDiv {
			width: 100%;
		}
	}
}

@media screen and (max-width: 576px) {
	.wrapperSection {
		.inputDiv {
			width: 100%;
		}
	}
}
