.judge-wrapper {
	display: flex;
	#teams-wrapper {
		display: flex;
		flex-direction: row;
		justify-content: start;
		width: 100%;
		height: 100%;
		overflow: unset;
		.single-team-title {
			font-size: 22px;
			font-weight: bold;
			max-height: 100px;
			padding: 20px;
			height: 55px;
			background-color: rgb(161, 183, 169);
			position: sticky;
			top: 0;
			border-bottom: 1px solid;
			display: flex;
			justify-content: center;
			align-items: center;
		}
		.single-team-content {
			padding: 0px;
		}
		.single-team {
			min-width: 250px;
			font-size: 12px;
			width: 100%;
			border-right: 1px solid;
		}
		.wrapperSection {
			margin: 0;
			h2 {
				font-size: 12px;
				font-weight: bold;
			}
		}
	}
	.score-wrap {
		overflow: unset;
	}
	.score-board-wrapper {
		position: sticky;
		top: 0;
		overflow: scroll;
		display: flex;
		flex-direction: column;
		width: 200px;
		padding: 0 10px;
		border-right: 1px solid black;
		height: 100vh;
		.scoreboard-team-wrapper {
			display: flex;
			justify-content: space-between;
			margin-bottom: 15px;
			border-bottom: 1px solid #b0b0b0;
			p {
				margin: 0;
			}
		}
	}
}
