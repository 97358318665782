body {
	background-color: rgb(255, 255, 255);
	box-sizing: border-box;
}

.App {
}

.App-header {
}

button {
	margin-top: 20px;
	display: inline-block;
	font-weight: 400;
	text-align: center;
	white-space: nowrap;
	border: 1px solid transparent;
	padding: 0.375rem 0.75rem;
	font-size: 1rem;
	line-height: 1.5;
	border-radius: 0.25rem;
	transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out,
		border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
	&.-light {
		color: #212529;
		background-color: #f8f9fa;
		border-color: #f8f9fa;
	}
	&:hover {
		color: #212529;
		background-color: #e2e6ea;
		border-color: #dae0e5;
	}
}

input[type="text"] {
	display: block;
	max-width: 500px;
	padding: 0.375rem 0.75rem;
	font-size: 1rem;
	font-weight: 400;
	line-height: 1.5;
	color: #212529;
	background-color: #fff;
	background-clip: padding-box;
	border: 1px solid #ced4da;
	appearance: none;
	border-radius: 0.25rem;
	transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
}
